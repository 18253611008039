import { SelectableOption } from './options/selectable-option';

export class Section {
    sectionName: string;
    periods: any;
    infoComponentId: number;
    extraOption: string;
}

export class SectionsViewModel {
    sections = new Array<SectionViewModel>();

    constructor(sections: Section[]) {
        sections.forEach((section: Section) => {
            const svm = new SectionViewModel();

            svm.text = section.sectionName;
            svm.infoComponentId = section.infoComponentId;

            svm.options = new Array<SelectableOption<number>>();

            for (const property in section.periods) {
                if (section.periods.hasOwnProperty(property)) {
                    const so = new SelectableOption<number>(false, property, section.periods[property][0]);
                    svm.options.push(so);
                }
            }

            if (svm.options.length > 0) {
                const firstOption = svm.options[0];
                firstOption.isSelected = true;
                svm.selectedOption = firstOption.value;
            }

            if (section.extraOption) {
                svm.extraOption = new SelectableOption(false, section.extraOption, false);
            }

            this.sections.push(svm);
        });
    }

    getPeriods(): Array<string> {
        const arr = new Array<string>();

        this.sections.forEach(sect => {
            const selected = sect.options.find(opt => opt.isSelected === true);
            let text = `${sect.infoComponentId}-${selected!.value}`;

            if (sect.extraOption) {
                text += '~';
                text += sect.extraOption.isSelected ? '1' : '0';
            }

            arr.push(text);
        });

        return arr;
    }
}

export class SectionViewModel {
    options: SelectableOption<number>[];
    selectedOption: number;
    text: string;
    infoComponentId: number;
    extraOption: SelectableOption<boolean>;
}
